
export function desktopMenu() {
    $(".desktop-menu .close").click(() => {
        $("#toggleMegaMenu").removeClass("show");
        $(body).removeClass("bodyFixed");
    });
    $(".navbar-toggler").click(() => {
        $(body).toggleClass("bodyFixed");
        $(".desktop-menu .navbar-nav .nav-item").removeClass("active");
        $(".desktop-menu .navbar-nav .nav-item:first-child").addClass('active');
        $(".desktop-menu .navbar-nav .nav-item ul").hide();
        $(".desktop-menu .navbar-nav .nav-item:first-child ul").show();
    });
    // Detect esc to close megamenu
    $(document).keyup(function (e) {
        if (e.key === "Escape") {
            $(".desktop-menu .close").click();
        }
    });

    // Close Megamenu when clicking oustside
    $("#toggleMegaMenu").click(function (event) {
        event.stopPropagation();
    });

    $(window).click(function () {
        if ($("#toggleMegaMenu").hasClass("show")) {
            $(".desktop-menu .close").click();
        }
    });

    $(".desktop-menu .navbar-nav .nav-item").each(function () {
        $(this).click(() => {
            $(".desktop-menu .navbar-nav .nav-item").removeClass("active");
            $(".desktop-menu .navbar-nav .nav-item ul").hide();
            $(this).addClass("active");
            $(this).find('ul').show();
        });
    });


    // Navigation Search
    let refreshPage = () => {
        // var newUrl = window.location.origin + window.location.pathname + "?searchTerm=" + $(".search-mobile input").val();
        var newUrl = window.location.origin + "/SearchResults?searchTerm=" + $("#navSearchInput").val();
        window.location.href = newUrl;
        return false;
    }
    $("#navSearchIcon").click(() => {
        $(".navbar-toggler, .nav-item:last-child").click();
    });
  
    // Focus input search
    $(".nav-item.search *:not(:last-child), #navSearchIcon").click(() => {
        $('#navSearchInput').focus();
    });
    $("#navSearchBtn").click(() => {
        refreshPage();
    });
    $('#navSearchInput').keypress(function (e) {
        if (e.which == 13) {
            refreshPage();
        }
    });

    // Disable first level on click
    $(".desktop-menu .left-nav ul.navbar-nav>li a.nav-link").click((e) => {
        e.preventDefault()
    });

    // Two column layout theme
    if ($(".right-rail").length) {
        $(".desktop-menu, .header-top").addClass("white-theme");
    }

    // Trap Keyboard inside megamenu Modal

    $(document).on('keydown', function (e) {
        var target = e.target;
        var shiftPressed = e.shiftKey;
        // If TAB key pressed
        if (e.keyCode == 9) {
            if ($(target).parents('#toggleMegaMenu').length) {
                var borderElem = shiftPressed ?
                    $(target).closest('#toggleMegaMenu').find('input:visible,select:visible,button:visible,textarea:visible,a:visible').first() :
                    $(target).closest('#toggleMegaMenu').find('input:visible,select:visible,button:visible,textarea:visible,a:visible').last();
                if ($(borderElem).length) {
                    if ($(target).is($(borderElem))) {
                        return false;
                    } else {
                        return true;
                    }
                }
            }
        }
        return true;
    });
}
/*english reset for google_translate_element*/
window.MyReset = () => jQuery('#\\:1\\.container').contents().find('#\\:1\\.restore').click();
export function googleTranslator() {
    //------------------------------Google Translate codes -----------------------------
    //----------------------------------------------------------------------------------
   
    function resetGT() {
            // do stuff
            var iframe = document.getElementsByClassName('goog-te-banner-frame')[0];
            if(!iframe) return;

            var innerDoc = iframe.contentDocument || iframe.contentWindow.document;
            var restore_el = innerDoc.getElementsByTagName("button");

            for(var i = 0; i < restore_el.length; i++){
                if(restore_el[i].id.indexOf("restore") >= 0) {
                restore_el[i].click();
                var close_el = innerDoc.getElementsByClassName("goog-close-link");
                close_el[0].click();
                return;
            }
        }
    }
    $('.lang-select').click(function () {
        var theLang = $(this).attr('data-lang');
        $('.goog-te-combo').val(theLang);
        //alert($(this).attr('href'));
        if (theLang === "en") {
            //window.location.href.split('#')[-1];
            //window.location = $(this).attr('href');
            //window.location.hash = '';
            //location.reload();
            resetGT();
            window.location.hash = 'googtrans(en|en)';
            window.location = $(this).attr('href');
            location.reload();
          }
          else
          {
            window.location = $(this).attr('href');
            location.reload();
          }
        //window.location = $(this).attr('href');
        //location.reload();      
    });
    if (window.location.href.indexOf("#googtrans") > -1) {
        $('#reset-text').html('Show Original');
        //$('.dropdown').empty();
        $("#reset").click(function () {
            ////
            var iframe = document.getElementsByClassName('goog-te-banner-frame')[0];
            if (!iframe) return;

            var innerDoc = iframe.contentDocument || iframe.contentWindow.document;
            var restore_el = innerDoc.getElementsByTagName("button");

            for (var i = 0; i < restore_el.length; i++) {
                if (restore_el[i].id.indexOf("restore") >= 0) {
                    restore_el[i].click();
                    var close_el = innerDoc.getElementsByClassName("goog-close-link");
                    close_el[0].click();
                    history.pushState("", document.title, window.location.pathname + window.location.search);
                    window.location.reload();
                    return;
                }
            }
          // alert("Yes Reset Clicked")
        });
    }
}

// Show dark overlay on resize
export function resizeWindowMenu() {
    if ($("#toggleMegaMenu").hasClass("show")) {
        $(body).addClass("bodyFixed");
    }
}

//Make navigation sticky
// $(function() {
//     var distance = $('.navBar').offset().top,
//       $window = $(window);
//       $window.scroll(function() {
//       var dh = $('.navBar').height();
//       $('.navBar').toggleClass('fixedtop', $window.scrollTop() >= distance, "easeOutSine");
//       if($('.navBar').hasClass('fixedtop')){
//         $('body').css('margin-top', dh+0 );
//       }else{
//         $('body').css('margin-top', 0 );
//       }
//     });
//   });

  document.querySelector('.hidden').scrollHeight
  $(window).scroll(function(){
    var scroll = $(this).scrollTop();
    $('.parallax-background').css({'background-position':'50% '+scroll/2+'px'});
  });
  
//Hide alert bar 
// addEventListener("load", () => {
//     var lastY = scrollY;
//     const MAX_SCROLL_DELTA = 5, NAV_HEIGHT = bannerAlert.offsetHeight;
//     addEventListener("scroll", () => {
//        const delta = scrollY - lastY;
//        if (Math.abs(delta) > MAX_SCROLL_DELTA) {
//            bannerAlert.classList.toggle("hidebannerAlert", delta > 0 && scrollY > NAV_HEIGHT);
//            lastY = scrollY;
//        }
//     });
// });